import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../redux/store';
import PickACardScreen from './PickACardScreen';
import OrBarComponent from '../../components/OrBarComponent';
import ScanToPayQrComponent from './ScanToPayQrComponent';
import { getOrderStatusNoHash } from './PaymentScreenFunctions';
import { setAppBackground, setAppBackgroundTwClass } from '../../redux/slices/layoutSlice';
import PizzahutIcon from '../../assets/pizzahut-icon.svg';
import BackButton from '../../components/Buttons/BackButton';

type ChoosePaymentScreenProps = {
    focusKey: string;
};
const ChoosePaymentScreen = ({ focusKey: focusKeyParam }: ChoosePaymentScreenProps) => {
    let fetchOrderStatusInterval;
    const OrderId = useSelector((state) => state.deliveryFormData.lastOrder._id);
    const { orderSubmissionError } = useSelector((state) => state.deliveryFormData);
    const [orderStatus, setOrderStatus] = useState<string>('');

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const { ref, focusKey, focusSelf } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
    });

    const handleBackButtonClick = () => {
        navigate('/checkout');
    };

    const handleGetOrderStatus = async () => {
        if (!OrderId) {
            return;
        }
        const cardOrderStatus = await getOrderStatusNoHash('card', OrderId);
        const googleAppleOrderStatus = await getOrderStatusNoHash('google_apple', OrderId);
        if (cardOrderStatus === 'success') {
            setOrderStatus(cardOrderStatus);
        } else if (googleAppleOrderStatus === 'success') {
            setOrderStatus(googleAppleOrderStatus);
        }
        return;
    };

    useEffect(() => {
        focusSelf();
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/blurred.jpg',
                'bg-no-repeat bg-cover bg-opacity-95',
            ]),
        );

        if (OrderId) {
            localStorage.setItem('LastOrderId', OrderId);
        }

        const tenSeconds = 1000 * 20;
        fetchOrderStatusInterval = setInterval(handleGetOrderStatus, tenSeconds);

        return () => {
            clearInterval(fetchOrderStatusInterval);
            localStorage.removeItem('LastOrderId');
        };
    }, []);

    useEffect(() => {
        if (orderStatus === 'success') {
            console.log('Order has been paid! navigating to tracking screen...');
            navigate(`/track-order/${OrderId}`);
        }
    }, [orderStatus]);

    useEffect(() => {
        if (orderSubmissionError) {
            console.log('Error:', orderSubmissionError);
            navigate('/checkout');
        }
    }, [orderSubmissionError]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className="flex justify-evenly bg-opacity-70 items-center h-full w-full">
                <img
                    className="absolute h-[6vw] bottom-[3vw] right-[3vw]"
                    src={PizzahutIcon}
                    alt="Pizzahut Logo"
                />
                <BackButton
                    className="absolute top-[5vw] right-[5vw] bg-white !bg-opacity-10"
                    focusClassName="!bg-opacity-30 !scale-110"
                    onClick={handleBackButtonClick}
                />
                <PickACardScreen focusKey="pick-a-card-screen" />
                <OrBarComponent />
                <ScanToPayQrComponent />
            </div>
        </FocusContext.Provider>
    );
};

export default ChoosePaymentScreen;
