import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconHeart, IconHeartFilled } from '@tabler/icons-react';
import { AppDispatch, useSelector } from '../../redux/store';
import { Item } from '../../redux/slices/dbFoodMenuSlice';
import { updateUserInfo } from '../../redux/slices/userAuthSlice';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    item: Item;
    children?: React.ReactNode;
};

const AddToWishlistButton = ({ item }: ButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const { user } = useSelector((state) => state.userAuth);
    const dispatch = useDispatch<AppDispatch>();

    const handleFavoriteClick = () => {
        if (!user) return;
        let newFavorites = [...user.favorites];
        if (newFavorites.find((favorite) => favorite._id === item._id)) {
            newFavorites = newFavorites.filter((favorite) => favorite._id !== item._id);
        } else {
            console.log(item);
            newFavorites.push(item);
        }
        const payload: any = {
            favorites: newFavorites,
        };
        dispatch(updateUserInfo({ payload }));
    };

    const { ref, focused } = useFocusable({
        onEnterPress: handleFavoriteClick,
    });

    return (
        <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                handleFavoriteClick();
            }}
            ref={ref}
            className={`rounded-full flex justify-center items-center w-[4.7vw] h-[3.5vw] py-[1.2vw] text-[2vw] mb-[0.5vw] ${focused || isHovered ? 'bg-pizzahut-red' : 'bg-white bg-opacity-20'}`}
        >
            {user && user.favorites && user.favorites.find((i) => i._id === item._id) ? (
                <IconHeartFilled size={`2vw`} strokeWidth={`0.06vw`} />
            ) : (
                <IconHeart size={`2vw`} strokeWidth={`0.06vw`} />
            )}
        </button>
    );
};

export default AddToWishlistButton;
