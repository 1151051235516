import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useRef, KeyboardEvent } from 'react';

type SearchInputProps = {
    onChange: (searchTerm: string) => void;
    classNameParams?: string;
    inputValue: string;
    placeHolderValue?: string;
};

export const SearchInput = ({
    onChange,
    classNameParams,
    inputValue,
    placeHolderValue,
}: SearchInputProps) => {
    const { ref, focused, focusKey } = useFocusable({
        onFocus: () => {
            searchInputRef.current?.focus();
        },
        onBlur: () => {
            console.log('SEARCH BLURRED');
            searchInputRef.current?.blur();
        },
    });
    const searchInputRef = useRef<HTMLInputElement>(null);

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            // If input has value, always handle arrow keys within input
            if (inputValue) {
                event.stopPropagation();
                event.preventDefault();
                const cursorPosition = searchInputRef.current?.selectionStart || 0;

                // For RTL text, we need to reverse the arrow key behavior
                if (event.key === 'ArrowLeft' && cursorPosition < inputValue.length) {
                    searchInputRef.current?.setSelectionRange(
                        cursorPosition + 1,
                        cursorPosition + 1,
                    );
                } else if (event.key === 'ArrowRight' && cursorPosition > 0) {
                    searchInputRef.current?.setSelectionRange(
                        cursorPosition - 1,
                        cursorPosition - 1,
                    );
                }
            } else {
                // If input is empty, only prevent right arrow navigation when cursor is not at the end
                if (event.key === 'ArrowRight') {
                    // Allow navigation to sidebar
                    return;
                }
                // Still handle left arrow normally within empty input
                event.stopPropagation();
            }
        }
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex items-center justify-center" ref={ref}>
                <input
                    ref={searchInputRef}
                    type="text"
                    dir="auto"
                    value={inputValue}
                    onChange={(e) => onChange(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder={placeHolderValue}
                    className={`bg-[transparent] border-white px-6 py-3 rounded-full ${classNameParams}
                    ${focused ? 'border-[6px] outline-none' : 'border-2'}`}
                />
            </div>
        </FocusContext.Provider>
    );
};
