import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import TransparentButton from '../../components/Buttons/TransparentButton';
import TransperentInput from '../../components/Inputs/TransperentInput';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../redux/store';
import { deleteError, initiateSmsAuth } from '../../redux/slices/userAuthSlice';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { Loading } from '../../components/Loading';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import QRCode from 'react-qr-code';
import BackButton from '../../components/Buttons/BackButton';
import { setAppBackground, setAppBackgroundTwClass } from '../../redux/slices/layoutSlice';
import { AlertModal } from '../../components/ConfirmModal/AlertModal';
import PizzahutIcon from '../../assets/pizzahut-icon.svg';

type LoginScreenProps = {
    focusKey: string;
};
const LoginScreen = ({ focusKey: focusKeyParam }: LoginScreenProps) => {
    const ConnectTvTranslateFunction = createTranslateFunction('login.connect_tv');
    const ConnectPhoneTranslateFunction = createTranslateFunction('login.connect_phone');
    const AlertModalTitlesTranslateFunction = createTranslateFunction('login.alert_modal');

    const userAuth = useSelector((state) => state.userAuth);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);
    const [alertModalTitle, setAlertModalTitle] = useState<string>(
        AlertModalTitlesTranslateFunction('placeholder'),
    );
    const { ref, focusKey } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'phonenumber-login-input',
    });

    const handleMobileNumberSubmit = async () => {
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber, { regionCode: 'IL' });
        if (!parsedPhoneNumber.number?.e164) {
            setAlertModalTitle(AlertModalTitlesTranslateFunction('invalid_phone_number'));
            setIsAlertModalOpen(true);
            setFocus('login-alert-modal');
            return;
        }
        await dispatch(initiateSmsAuth({ phone_number: parsedPhoneNumber.number?.e164 }));
        localStorage.setItem('phoneNumber', parsedPhoneNumber.number?.e164);
    };

    useEffect(() => {
        setFocus(focusKey);
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/blurred.jpg',
                'bg-no-repeat bg-cover bg-opacity-95',
            ]),
        );
    }, []);

    useEffect(() => {
        if (userAuth.session) {
            console.log('GOT INTO THE IF!');
            localStorage.setItem('session', userAuth.session);
            navigate('/loginverify');
        }
    }, [userAuth]);

    useEffect(() => {
        if (phoneNumber.length > 9) {
            setFocus('submit-phonenumber-button');
        }
    }, [phoneNumber]);

    useEffect(() => {
        if (userAuth.error) {
            setAlertModalTitle(AlertModalTitlesTranslateFunction('placeholder'));
            setIsAlertModalOpen(true);
            setFocus('login-alert-modal');
        }
    }, [userAuth.error]);

    return (
        <FocusContext.Provider value={focusKey}>
            {userAuth.loading === true ? (
                <Loading />
            ) : (
                <div
                    ref={ref}
                    className="flex justify-center bg-opacity-70 items-center h-full w-full"
                >
                    {isAlertModalOpen && (
                        <AlertModal
                            close={() => {
                                dispatch(deleteError());
                                setIsAlertModalOpen(false);
                                setFocus('phonenumber-login-input');
                            }}
                            focusKey="login-alert-modal"
                            opened={isAlertModalOpen}
                            title={alertModalTitle}
                        />
                    )}
                    <img
                        className="absolute h-[6vw] bottom-[3vw] right-[3vw]"
                        src={PizzahutIcon}
                        alt="Pizzahut Logo"
                    />
                    <BackButton className="absolute top-[4vw] right-[4vw]" />
                    <div className="flex flex-col items-center justify-start h-[70%] w-[40%] pt-[6vw]">
                        <div className={`flex flex-col justify-evenly`}>
                            <span
                                className={`text-[2vw]`}
                            >{`${ConnectTvTranslateFunction(`title`)}`}</span>
                            <span className="text-[1.2vw] mb-[4.5vw]">{`${ConnectTvTranslateFunction(`description`)}`}</span>
                            <div className="flex flex-col justify-center items-center">
                                <TransperentInput
                                    className="mb-[1.8vw] !rounded-[5vw]"
                                    placeholderValue={`${ConnectTvTranslateFunction(`input_placeholder`)}`}
                                    focusKey="phonenumber-login-input"
                                    setStateValue={setPhoneNumber}
                                ></TransperentInput>
                                {phoneNumber.length > 9 && (
                                    <TransparentButton
                                        onClick={handleMobileNumberSubmit}
                                        className="w-[40%] !rounded-itemRadius"
                                        focusKey="submit-phonenumber-button"
                                    >{`${ConnectTvTranslateFunction(`send_a_code_button`)}`}</TransparentButton>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-evenly h-[68%] w-[10%]">
                        <div className="border-full h-[36%] my-[0.5vw] w-[0.2vw] bg-[#ffffff] bg-opacity-25 text-[0.1vw]"></div>
                        <span className="text-[1.4vw]">{`${ConnectTvTranslateFunction(`or`)}`}</span>
                        <div className="border-full h-[36%] my-[0.5vw] w-[0.2vw] bg-[#ffffff] bg-opacity-25 text-[0.1vw]"></div>
                    </div>
                    <div className="flex flex-col items-center justify-start h-[70%] w-[40%] pt-[6vw]">
                        <div className="flex flex-col mb-[2vw]">
                            <span
                                className={`text-[2vw]`}
                            >{`${ConnectPhoneTranslateFunction(`title`)}`}</span>
                            <span className="text-[1.2vw]">{`${ConnectPhoneTranslateFunction(`description`)}`}</span>
                        </div>
                        <QRCode
                            className="w-[16vw] h-[16vw] mb-[2vw]"
                            value="https://mobile-pizzahut.tlvtech.io/"
                        />
                        <TransparentButton
                            onClick={() => navigate('/register')}
                        >{`${ConnectPhoneTranslateFunction('insert_verification_code_button')}`}</TransparentButton>
                    </div>
                </div>
            )}
        </FocusContext.Provider>
    );
};

export default LoginScreen;
